// Colore principale tag
$main-tag-color: #2979ff;
$main-tag-hover-color: #0d47a1;

// Colore principale
$main-color: $main-tag-color;
// Colore principale testi voci menu
$button-default-color: $main-color;

$widget-border-radius: .25rem;
$widget-highlight-color: $main-tag-color;

// Colore principale testi voci menu
// $menu-color: $main-tag-color;
// $menu-background-color: #fff;
$menu-active-color: #fff;

// Dimensione del separatore tra griglia / scheduler
$splitter-size: .5rem;
// Dimensione del separatore tra griglia / scheduler all'hover
$splitter-hover-size: .5rem;
$splitter-background-color: #dfdfe5;

// Background dellla toolbar
$panel-toolbar-background-color: #fafafa;

// Colore principale testi voci menu
/*$list-color: #000000DE;
$list-background-color: #fff;
$list-active-color: $main-tag-color;*/
$list-highlight-color: #7575751f;

// Colore bordo campi input
$field-highlight-color: $main-tag-color;
$field-active-label-color: $main-tag-color;

$mask-background-color: $main-tag-color;
$mask-color: #fff;

$error-tip-background-color: rgb(254 202 202);
